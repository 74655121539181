import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './buyMatic.module.sass';
import { useWeb3React } from '@web3-react/core';
import { getDatabase, ref, onValue } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import OnMeta from './onmeta';

const BuyMatic = () => {
  const auth = getAuth();
  const db = getDatabase();
  const { account } = useWeb3React();
  const [emailId, setEmailId] = useState(localStorage.getItem('email'));

  const chainId = '137';

  const getData = (uid) => {
    const userId = auth?.currentUser?.uid;
    return onValue(
      ref(db, process.env.REACT_APP_FIREBASE_URL + userId),
      (snapshot) => {
        const username = (snapshot.val() && snapshot.val().email) || 'username';
        setEmailId(username);
      },
      {
        onlyOnce: true,
      }
    );
  };

  useEffect(() => {
    getData();
  }, [emailId, account]);
  return (
    <>
      <OnMeta emailId={emailId} address={account} />
    </>
  );
};

export default BuyMatic;
