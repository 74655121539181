import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Link, useParams, useHistory } from 'react-router-dom';
import styles from './Card2.module.sass';
import Icon from '../Icon';
import { config } from '../../config';
import Bid from '../../mocks/bids';
import Grp from '../../components/img/Group 239.png';
import noImg from '../../images/no img.png';
import { ethers, utils } from 'ethers';
import Token1155 from '../abi/NftToken1155.json';
import { useWeb3React } from '@web3-react/core';
import Loader from '../Loader';
import moment from 'moment';
import { useSnackbar } from 'notistack';

const Card2 = ({ className, item }) => {
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState('');
  const counter = useRef(0);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();

  useEffect(() => {}, [active]);
  const onLoad = useCallback(() => {
    counter.current = 1;
    if (counter.current <= item?.metadata?.image?.length) {
      setLoading(false);
      setIsLoading(false);
      setImage(item?.metadata?.image);
    }
  }, []);
  const onLoads = useCallback(() => {
    counter.current = 1;
    if (counter.current <= item?.metadata?.animation_url?.length) {
      setLoading(false);
      setIsLoading(false);
      // setImage(item.metadata.animation_url);
    }
  }, []);

  const detailId = parseInt(item?.id?.tokenId).toString();
  return (
    <div className={cn(styles.card, className)}>
      <div
        className={styles.link}
        // to={active ? `/detail/${detailId}` : null}
        onClick={() => {
          if (!active) {
            return enqueueSnackbar('Please connect your wallet', {
              variant: 'error',
            });
          } else {
            history.push(`/detail/${detailId}`);
          }
        }}
      >
        {item.metadata.animation_url ? (
          <>
            {loading && (
              <Loader className={loading ? styles.loader1 : styles.loader2} />
            )}
            <video
              style={{ display: loading ? 'none' : 'block' }}
              autoPlay
              muted
              loop
              src={item.metadata.animation_url}
              type="video/mp4"
              className={styles.mainImage}
              onLoad={onLoads}
            />
          </>
        ) : (
          <>
            {isLoading && (
              <Loader className={isLoading ? styles.loader1 : styles.loader2} />
            )}
            <img
              style={{ display: isLoading ? 'none' : 'block' }}
              className={styles.mainImage}
              src={item.metadata.image || noImg}
              alt="Card"
              onLoad={onLoad}
              onContextMenu={(e) => e.preventDefault()}
            />
          </>
        )}

        <div className={styles.wrapper}>
          <div className={styles.avatarWrapper}>
            {item.metadata.animation_url ? (
              <>
                {loading && (
                  <Loader
                    className={loading ? styles.loader2 : styles.loader1}
                  />
                )}
                <video
                  style={{ display: loading ? 'none' : 'block' }}
                  autoPlay
                  muted
                  loop
                  src={item.metadata.animation_url}
                  type="video/mp4"
                  className={styles.avatar}
                  onLoad={onLoads}
                />
              </>
            ) : (
              <>
                {isLoading && (
                  <Loader
                    className={isLoading ? styles.loader2 : styles.loader1}
                  />
                )}
                <img
                  style={{ display: isLoading ? 'none' : 'block' }}
                  className={styles.avatar}
                  src={item.metadata.image || noImg}
                  alt="Card"
                  onLoad={onLoad}
                  onContextMenu={(e) => e.preventDefault()}
                />
              </>
            )}
          </div>
          <div className={styles.title}>
            <h4>{item.metadata.name}</h4>
            {/* <p>{moment(item.contractMetadata.openSea.lastIngestedAt).format("DD-MM-YYYY")}</p> */}
          </div>
          {item?.contractMetadata?.tokenType === 'ERC1155' && (
            <div className={styles.qty}>Qty: {item.balance} </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card2;
