import React, { useEffect } from 'react';

const OnMeta = (props) => {
  const loadScripts = async ({ srcUrl, id }) => {
    const newPromise = new Promise((resolve, reject) => {
      try {
        const scriptTag = document.createElement('script');
        scriptTag.src = srcUrl;
        scriptTag.id = id;
        scriptTag.async = true;
        scriptTag.type = 'text/javascript';
        scriptTag.addEventListener('load', () => {
          console.log(`${srcUrl} script is loaded`);
          resolve(true);
        });
        document.body.insertBefore(scriptTag, document.body.firstChild);
      } catch (error) {
        console.log(error);
        reject(false);
      }
    });
  };

  const onmetaCall = async () => {
    if (window.onMetaWidget) {
      let createWidget = new window.onMetaWidget({
        elementId: 'wowTakies-onmeta',
        environment: 'production',
        apiKey: process.env.REACT_APP_ONMETA_API_KEY,
        userEmail: props.emailId,
        walletAddress: props.address,
        chainId: 137,
      });
      createWidget.init();
      createWidget.on('ORDER_COMPLETED_EVENTS', (data) =>
        console.log('ORDER_COMPLETED_EVENTS -----------> ', data)
      );
      createWidget.on('ORDER_EVENTS', (data) =>
        console.log('ORDER_EVENTS ----------->')
      );
      return;
    }
  };

  useEffect(() => {
    onmetaCall();
  }, []);
  return <div id="wowTakies-onmeta"></div>;
};

export default OnMeta;
