import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Styles from './Card1.module.sass';
import Icon from '../Icon';
import { GetMaticPrice } from '../../services/ApiServices';
import { config } from '../../config';
import Bid from '../../mocks/bids';
import Grp from '../../components/img/Group 239.png';
import { MdVerified } from 'react-icons/md';
import Loader from '../Loader';

const Card1 = ({ className, item, collection, props }) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState('');
  const counter = useRef(0);

  const getUSD = async () => {
    let result;
    try {
      result = await GetMaticPrice();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result) {
        setValue(result.USD);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getUSD();
    // onLoad()
  }, [loading, collection?.featuredImage]);

  const onLoad = useCallback(() => {
    counter.current = 1;
    if (counter.current <= collection.featuredImage.length) {
      setLoading(false);
      setIsLoading(false);
      setImage(collection?.featuredImage);
    }
  }, []);
  return (
    <Fragment>
      <div className={cn(Styles.card)}>
        <Link
          to={
            collection?.tokenStandard === 'ERC-1155'
              ? '/collections/' + collection._id
              : '/collectionDetail/nfts/' + collection._id
          }
        >
          {loading && <Loader className={Styles.loader} />}
          <img
            style={{ display: loading ? 'none' : 'block' }}
            src={collection.featuredImage}
            alt="avatar"
            onLoad={onLoad}
            className={Styles.avatar}
            //onContextMenu={(e) => e.preventDefault()}
          />
          <div className={Styles.body}>
            {isLoading && (
              <Loader className={loading ? Styles.loader1 : Styles.loader2} />
            )}
            <img
              style={{ display: isLoading ? 'none' : 'block' }}
              src={collection.logo}
              className={Styles.revealGif}
              onLoad={onLoad}
              alt="logo"
              // onContextMenu={(e) => e.preventDefault()}
            />
          </div>
          <div className={cn(Styles.cardcontainer)}>
            <div className={Styles.header}>
              <div className={Styles.title}>
                {collection.title.length > 20
                  ? collection.title.slice(0, 20) + '....'
                  : collection.title}
              </div>
              <span>
                <MdVerified className={Styles.icon} />
              </span>
            </div>
            <div className={Styles.details}>
              <span className={Styles.createdBy}>Created by</span>
              <span className={Styles.createdBy}>
                {collection.createdBy ? collection.createdBy : collection.title}
              </span>
              {/* <span> */}
              {/* <p className="">
                <b>{collection.displayName}</b>
              </p> */}
              <MdVerified className={Styles.verifyIcon} />
              {/* </span> */}
            </div>
            <p> {collection.description.slice(0, 70) + '....'}</p>
          </div>
        </Link>
      </div>
    </Fragment>
  );
};

export default Card1;
