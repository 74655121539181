import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Home.module.sass';
import {
  Getcollections,
  GetCollectionsByTags,
} from '../../services/ApiServices';
import Card1 from '../../components/Card1';
import Loader from '../../components/Loader';
import OnMeta from '../BuyMatic/onmeta';
const Collection = () => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [collections, setCollections] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [category, setCategory] = useState([]);
  const [data, setData] = useState('');
  const [sample, setSample] = useState([]);

  const navLinks = [
    { x: 'AR Avatar', tags: 'AR Avatar' },
    { x: 'Face Filters', tags: 'Face Filters' },
    { x: 'Face Swap', tags: 'Face Swap' },
    { x: 'GIFs', tags: 'GIFs' },
  ];

  const navLink = [
    { x: 'AR Avatar', tags: 'AR Avatar' },
    { x: 'Face Filters', tags: 'Face Filters' },
    { x: 'Face Swap', tags: 'Face Swap' },
    { x: 'GIFs', tags: 'GIFs' },
    { x: 'Other Collections', tags: 'Setvi' },
  ];

  const getcollections = async (category) => {
    setLoading(true);
    let result;
    try {
      result = await GetCollectionsByTags(category);
    } catch (e) {
      console.log(e);
    }
    try {
      if (result.success) {
        setCollections(result.collection.docs);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getAllCollections = async () => {
    setIsLoading(true);
    let result;
    try {
      result = await Getcollections();
    } catch (e) {
      console.log(e);
    }
    try {
      if (result.success) {
        let array = [];
        result.collection.docs.map((x) => {
          x.category.map((data) => {
            array.push(data);
          });

          // setCategory((category) => [...category, { label: x.category }])
        });
        const uniqueNames = array.filter((val, id, array1) => {
          return array1.indexOf(val) == id;
        });
        getcollections(uniqueNames[0]);
        setData(uniqueNames[0]);
        setCategory(uniqueNames);

        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCollections();
  }, []);

  // useEffect(() => {
  //   if(category !== []){
  //     console.log(category)
  //   getcollections(category[0]);
  //   setData(category[0])
  //   }
  // },[category])

  return (
    <>
      <div className={cn('container', styles.container)}>
        <h3 className={cn('h4', styles.title)}>Collections</h3>
        {/* <OnMeta /> */}
        {/* <div className={styles.nav}>
          {process.env.REACT_APP_OTHER_COLLECTION ? (
            <>
              {navLink.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => {
                    setActiveIndex(index);
                    getcollections(x.tags);
                  }}
                  key={index}
                >
                  {x.x}
                </button>
              ))}
            </>
          ) : (
            <>
              {navLinks.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => {
                    setActiveIndex(index);
                    getcollections(x.tags);
                  }}
                  key={index}
                >
                  {x.x}
                </button>
              ))}
            </>
          )}
        </div> */}
        {isLoading ? (
          <Loader className={styles.loader} />
        ) : (
          <div>
            <div className={styles.nav}>
              {category.map((x, index) => (
                // <>
                // {x.label.map((x,Index) => (
                <button
                  className={data === x ? styles.activeLink : styles.link}
                  onClick={() => {
                    getcollections(x);
                    setData(x);
                  }}
                  key={index}
                >
                  {x}
                </button>
                // ))}
                // </>
              ))}
            </div>
            {loading ? (
              <Loader className={styles.loader} />
            ) : (
              <div className={styles.row}>
                {collections
                  .sort((a, b) => a.id - b.id)
                  .map((collection, index) => (
                    // <Col md={6} sm={12} xs={24} lg={6}>
                    <Card1
                      className={styles.card}
                      collection={collection}
                      key={index}
                    />
                    // </Col>
                  ))}
                {/* </Row> */}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default Collection;
