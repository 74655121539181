let CONFIG = {}

CONFIG.nftAddress = "0xa4bF92b34096B4456D7EF202c79e888BaBf68E9A"
CONFIG.whitelistAddress = "0x2C040111F720b72f9937B035BE1110A74CBBDC9a"
CONFIG.whitelistAddressv4 = "0xC5A1Bc6609D76767E5B8ed077444239cb1F559A2"

CONFIG.OWNER_ADDRESS = "0x1ED931cd11E36EE008DAc6d98051F3dFf5Bc3289";

CONFIG.OMINIEADEN_NFT_ADDRESS ="0xec6D7881f8b2ADF278e258e25dD939e62B805Ac3";

CONFIG.ContractAddress721 = "0x176972430eB84EE4c814b6F185d81457b8AFaa6a"
CONFIG.ContractAddress1155 = "0xE10c7EEBc638340f736015147eE8dA050cb93438"
CONFIG.MarketFactoryAddress = "0x9c364dFdD1e91C9F19A4323509B5d636D8284125"

CONFIG.BlockchainUrl = "https://polygonscan.com/address/"

module.exports = CONFIG